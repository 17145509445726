<template lang="pug">
.test
  iframe.smart-create-iframe(
    ref='smartCreateIframe',
    :src='smartCreateUrl',
    width='600',
    height='700'
  )
</template>

<script>
export default {
  name: 'Test',
  computed: {
    canvasUrl() {
      return process.env.VUE_APP_CANVAS_URL;
    },
    smartCreateUrl() {
      return `${this.canvasUrl}/smart-create?step=2`;
    },
  },
  mounted() {
    window.addEventListener('message', (event) => {
      if (event.origin.startsWith(this.canvasUrl)) {
        console.log('event', event.data);
        if (event.data.close) {
          this.$emit('close-modal');
        }

        if (event.data.projectId) {
          const query = event.data.query || {};

          this.$router.push({ name: 'project', params: { projectId: event.data.projectId }, query });
        }
      }
    });
  },
};
</script>

<style lang="scss">
.smart-create-iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  border: 0;
}
</style>
